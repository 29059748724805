import { Injectable, Injector } from '@angular/core';
import { HttpResponse,HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { BehaviorSubject,AsyncSubject } from 'rxjs/';
import { catchError,mergeMap, map, filter, tap, take, switchMap, finalize } from "rxjs/operators";

import { AuthenticationService, } from  '../services/authentication.service';
import { environment } from '../../environments/environment';
import { User} from '../models/data.types';
import 'rxjs/add/operator/do';
 
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  isRefreshingToken: boolean = false;
  auth : AuthenticationService;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  tokenHeaders: HttpHeaders;
  tokenUrl: string;
  adTokenUrl: string;

  constructor(private http:HttpClient,
              private injector : Injector,
              private apiService: ApiService) {
    this.tokenHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.tokenUrl = environment.apiUrl+ `service/token`;
    this.adTokenUrl = environment.apiUrl+ `service/api/Account/AdLogin`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.auth = this.injector.get(AuthenticationService);    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });
    
    return next.handle(request)
      .pipe(
      catchError((error, ca) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
            if(this.auth.getUser() != null){
              return this.handle401Error(request, next);
            }  
            else
            {
                this.apiService.logout();
            }
            default:
              return _throw(error);
          }
        } else {
          return _throw(error);
        }
      })
      )
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      const http = this.injector.get(HttpClient);

      let u : User = this.auth.getUser()

      if (u.LoginType == "AD")
      {
        let tokenRequest = 'username=' + u.username + '&password=' + u.password;

        return http.post(this.adTokenUrl,tokenRequest, {headers: this.tokenHeaders}).pipe(
          switchMap((newToken: any) => {
            if (newToken) {
              this.auth.setToken(newToken.access_token)
              this.tokenSubject.next(newToken.access_token)
              return next.handle(req);
            }
            this.apiService.logout()
            console.log('Could not refresh token 1')
          }),
          catchError(error => {
            if(error.status == 401)
            {
              return _throw(error);
            }
            else
            {
              this.apiService.logout()
              console.log('Could not refresh token 2')
            }
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
          )
      }
      else
      {
        let tokenRequest = 'grant_type=password&username=' + u.username + '&password=' + u.password;

        return http.post(this.tokenUrl,tokenRequest, {headers: this.tokenHeaders}).pipe(
          switchMap((newToken: any) => {
            if (newToken) {
              this.auth.setToken(newToken.access_token)
              this.tokenSubject.next(newToken.access_token)
              return next.handle(req);
            }
            this.apiService.logout()
            console.log('Could not refresh token 1')
          }),
          catchError(error => {
            if(error.status == 401)
            {
              return _throw(error);
            }
            else
            {
              this.apiService.logout()
              console.log('Could not refresh token 2')
            }
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
          )
      }
     
    } else {
      return this.tokenSubject
        .pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(req);
        })
        )
    }
  }
  
}
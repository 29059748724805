export class RequestParameter {
    Name: string;
    Value: string;
}

export class QueryStringParameter {
    Name: string;
    Value: string;
}

export class NonQuery {
    AffectedData: number;
    hasError: boolean;
    errorMessage: string;
}

export class UploadResponse {
    Result: string;
    hasError: boolean;
    errorMessage: string;
}

export class DataTable {
    Items: any;
    HasError: boolean;
    ErrorMessage: string;
    SelectedItemText: string;
    SelectedItemValue: any;
}

export class SingleObject {
    Data: any;
    HasError: boolean;
    ErrorMessage: string;
    SelectedItemText: string;
    SelectedItemValue: any;
}

export class Scalar {
    Data: any;
    HasError: boolean;
    ErrorMessage: string;
}

export class Session {
    access_token: string;
    token_type: string;
    expires_in: number;
    issued: Date;
    expires: Date;
    error: string;
    error_description: string;
}


export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token: any;
    session: any;
    isDeveloper: boolean;
    ProfileId: number;
    ProfileName: string;
    LoginType: string;
    SelectedHomeGUID: string;
    roles: string[];
    TimeDifference:number;
    ZoneName:string;
    ZoneOffset:string;
}

export interface UserForm {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

export interface EditUserForm {
    email: string;
    username: string;
    lockoutenabled: boolean;
    phonenumber: string;
    userid?: string;
    accessfailedcount: number;
    emailconfirmed: boolean;
    phonenumberconfirmed: boolean;
    twofactorenabled: boolean;

}

export interface Role {
    rolename: string;
}

export interface RoleResponse {
    id: string;
    name: string;
}

export class Shell {
    id: number;
    name: string;
    description: string;
    script: string;
    css: string;
    menu: any;
}

export class Page {
    Id: number;
    Name: string;
    Title: string;
    Route: string;
    Header: string;
    Template: string;
    Footer: string;
    Script: string;
}

export interface IPageItem{
    id?: number;
    name: string;
    route: string;
    template: string;
    fulltemplate?: string;
    ispublic: boolean;
}

export interface PageSecurity {
    Id: number;
    PageId: number;
    UserName: string;
    DateCreated: string;
    CanView: boolean;
    CanEdit: boolean;
    CanSecure: boolean;
}

export interface ConnectionString {
    Id: number;
    name: string;
    connectionString: string;
}

export interface Function {
    Id: number;
    PageId: number;
    Name: string;
    ConnectionId: number;
    TriggerId: number;
    ReturnTypeId: number;
    Parameters: Parameter[];
    IsProcedure: boolean;
    Timeout: number;
    Sql: string;
    BeforeExec: string;
    OnSuccess: string;
    OnError: string;
}

export interface PageFunction {
    Id: number;
    PanelId: number;
    ReturnTypeId: number;
    Name: string;
    OnLoad: boolean;
    OnChange: boolean;
    BeforeExec: string;
    OnSuccess: string;
    OnError: string;
    Parameters: Parameter[];
    Results : QueryResults;
}

export interface LocalAPIs {
    PageFunctions: PageFunction[];
    Errors: string[];
}

export class QueryResults {
    Items: any;
    RecordCount: number;
    HasError: boolean;
    ErrorMessage: string;
}

export interface FunctionParameter {
    Id: number;
    Name: string;
    DefaultValue: string;
}

export interface Parameter {
    Id: number;
    FunctionId: number;
    Name: string;
    DefaultValue: string;
    IsRequired: boolean;
}

export interface Trigger {
    Id: number;
    Name: string;
}

export interface ReturnType {
    Id: number;
    Name: string;
}
export interface DictionaryItem<K, V> {
    0: K,
    1: V
  };


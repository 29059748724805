import { Component, NgZone, ViewChild, ViewEncapsulation, ViewContainerRef, Compiler, NgModule, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Platform, AlertController } from '@ionic/angular';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api.service';
import { ShellService } from './services/shell.service';
import { environment } from './../environments/environment'
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, NavController } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Guid } from "guid-typescript";
import { DateTime } from '@mobiscroll/angular/src/js/presets/datetime';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { Router, ActivatedRoute } from '@angular/router';
import { inherits } from 'util';
declare var cordova: any;

@Component({
  selector: 'app-root',
  template: `<ng-container #dynamicAppComponent></ng-container>`
})
export class AppComponent implements OnInit, AfterViewInit {
  public appPages = [];

  @ViewChild('dynamicAppComponent', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private compiler: Compiler,
    private shellService: ShellService,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private localNotifications: LocalNotifications,
    private deeplinks: Deeplinks,
    public nav: NavController,
    private router: Router,
    public zone: NgZone
  ) {
    this.initializeApp();
    //shellService.loadShell().then(() => this.addComponent(shellService.shell.template));
    this.apiService.postAs<any>('data/doquery/getshellbyname', { name: environment.shellName, username: environment.username }).then(x => {
      this.addComponent(x.shell.template);
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    //this.initializeApp();
  }

  initializeApp() {
    //this.notify();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {
        this.setupPush();


        let self = this;
        cordova.exec(function (link) {
          console.log('Cordova Link');
          console.log(link);
          debugger;
          if (link != null) {
            if (link.path.indexOf('/homehubb-Thankyou') > -1) {
              let path = link.path.split("/", 3);
              console.log(path);
              self.zone.run(() => {
                self.router.navigate([`/${path[1]}`], { queryParams: { token: path[2] } });
              });
              //this.nav.navigateForward('/'+nomatch.$link.path);
            }
            else if (link.path.indexOf('/mobile-servicerequestsignoff') > -1) {
              let path = link.path.split("/", 3);
              console.log(path);
              self.zone.run(() => {
                var user = JSON.parse(localStorage.getItem('currentUser'));
                if (user != null && path[2]) {
                  self.apiService.postAs('data/doquery/mobile-GetHomeByIssue', { UserName: user.username, GUID: path[2] }).then(function (response: any) {
                    if (response.Result) {
                      user.SelectedHomeGUID = response.Result.GUID;

                      localStorage.setItem('currentUser', JSON.stringify(user));
                    }
                  }, (error) => {
                    console.log(error);
                  });
                }
                self.router.navigate([`/main/${path[1]}`], { queryParams: { guid: path[2] } });
              });
              //this.nav.navigateForward('/'+nomatch.$link.path);
            }
            else if (link.path.indexOf('/mobile-servicerequestappointment') > -1) {
              let path = link.path.split("/", 3);
              self.zone.run(() => {
                var user = JSON.parse(localStorage.getItem('currentUser'));
                if (user != null && path[2]) {
                  self.apiService.postAs('data/doquery/mobile-GetHomeByAppointment', { UserName: user.username, GUID: path[2] }).then(function (response: any) {
                    if (response.Result) {
                      user.SelectedHomeGUID = response.Result.GUID;

                      localStorage.setItem('currentUser', JSON.stringify(user));
                    }
                  }, (error) => {
                    console.log(error);
                  });
                }
                self.router.navigate([`/main/${path[1]}`], { queryParams: { guid: path[2] } });
              });
              //this.nav.navigateForward('/'+nomatch.$link.path);
            }
            else if (link.path.indexOf('/mobile-notificationdetails') > -1) {
              let path = link.path.split("/", 3);
              self.router.navigate([`/main/${path[1]}`], { queryParams: { guid: path[2] } });
              //self.router.navigate(['/main/mobile-notificationdetails']);
            }
            else if (link.path.indexOf('/mobile-notifications') > -1) {
              self.router.navigate(['/main/mobile-notifications']);
            }
            else if (link.path.indexOf('/mobile-maintenanceschedule') > -1) {
              self.router.navigate(["/main/mobile-maintenanceschedule"]);
            }
            else if (link.path.indexOf('/homehubb-Resetpassword') > -1) {
              let path = link.path.split("/", 3);
              console.log(path);
              self.zone.run(() => {
                self.router.navigate([`/${path[1]}`], { queryParams: { token: path[2] } });
              });
            }
            else {
              self.router.navigate(['/main/mobile-notifications']);
              //self.CantNavigate();
            }
          }
        }, null, 'IonicDeeplinkPlugin', 'onDeepLink', []);
      }

    });

  }

  async notify() {
    if (localStorage.getItem('IsFirstTime') == null) {
      localStorage.setItem('IsFirstTime', "1");
      await this.notifyAlert();
    }
  }

  setupPush() {
    let self = this;

    this.oneSignal.startInit('b0f122eb-3823-495e-8f99-2c4812767533', 'com.homehubb.app');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    this.oneSignal.handleNotificationReceived().subscribe(data => {

      console.log('New Notification Received');

      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;


      console.log('msg: ' + msg);

      console.log('title: ' + title);

      if (data.isAppInFocus) {
        // if application open, show popup
        this.presentAlert(title, msg, additionalData);
      } else {
        //this.handleNotification(additionalData);
      }




      this.localNotifications.schedule({
        id: this.getHash(title + msg + new Date().getMilliseconds),
        title: title,
        text: msg,
      });

      //this.showAlert(title, msg, additionalData.task);
      //this.showAlert(title, msg, null);
    });

    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // let additionalData = data.notification.payload.additionalData;

      // this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });

    this.oneSignal.endInit();
  }

  async presentAlert(title, msg, additionalData) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [{
        text: 'Ignore',
        role: 'cancel'
      }, {
        text: 'View',
        handler: () => {
          this.handleNotification(additionalData);
        }
      }]
    });

    await alert.present();
  }

  async CantNavigate() {
    const alert = await this.alertCtrl.create({
      message: 'Navigation Failed',
      buttons: [{
        text: 'Ok',
        role: 'cancel'
      }
      ]
    });

    await alert.present();
  }

  async notifyAlert() {
    const alert = await this.alertCtrl.create({
      message: 'Your Home Builder would like to send you notifications',
      buttons: [{
        text: 'Ok',
        role: 'cancel'
      }
      ]
    });

    await alert.present();
  }

  handleNotification(additionalData) {
    let self = this;
    if (additionalData != null && additionalData.page) {

      switch (additionalData.page) {
        case "mobile-servicerequestsignoff":
          var user = JSON.parse(localStorage.getItem('currentUser'));
          if (user != null && additionalData.guid) {
            this.apiService.postAs('data/doquery/mobile-GetHomeByIssue', { UserName: user.username, GUID: additionalData.guid }).then(function (response: any) {
              if (response.Result) {
                user.SelectedHomeGUID = response.Result.GUID;

                localStorage.setItem('currentUser', JSON.stringify(user));
              }
            }, (error) => {
              console.log(error);
            });
          }
          self.router.navigate(["/main/mobile-servicerequestsignoff"], { queryParams: additionalData });
          break;
        case "mobile-servicerequestappointment":
          var user = JSON.parse(localStorage.getItem('currentUser'));
          if (user != null && additionalData.guid) {
            this.apiService.postAs('data/doquery/mobile-GetHomeByAppointment', { UserName: user.username, GUID: additionalData.guid }).then(function (response: any) {
              if (response.Result) {
                user.SelectedHomeGUID = response.Result.GUID;

                localStorage.setItem('currentUser', JSON.stringify(user));
              }
            }, (error) => {
              console.log(error);
            });
          }
          self.router.navigate(["/main/mobile-servicerequestappointment"], { queryParams: additionalData });
          break;
        case "mobile-notifications":
          self.router.navigate(["/main/mobile-notifications"]);
          break;
        case "mobile-notificationdetails":
          if (additionalData.guid) {
            self.router.navigate(["/main/mobile-notificationdetails"], { queryParams: { GUID: additionalData.guid } });
          }
          break;
        case "mobile-maintenanceschedule":
          self.router.navigate(["/main/mobile-maintenanceschedule"]);
          break;
        default:
          self.router.navigate(["/main/mobile-home"]);
      }
    }
    else {
      self.router.navigate(["/main/mobile-home"]);
    }
  }

  getHash(input) {
    var hash = 0, len = input.length;
    for (var i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + input.charCodeAt(i);
      hash |= 0;
    }
    return hash;
  }

  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: ['Dismiss']
    })
    alert.present();
  }


  private addComponent(componentTemplate: string) {

    @Component({ template: componentTemplate, encapsulation: ViewEncapsulation.None })
    class DynamicAppComponent { constructor(private shellService: ShellService, private apiService: ApiService) { } }

    @NgModule({
      declarations: [
        DynamicAppComponent
      ],
      imports: [
        //Deeplinks,
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        IonicModule,
      ]
    })
    class DynamicAppComponentModule { }

    const mod = this.compiler.compileModuleAndAllComponentsSync(DynamicAppComponentModule);
    const factory = mod.componentFactories.find((comp) =>
      comp.componentType === DynamicAppComponent
    );
    const component = this.container.createComponent(factory);
  }
}


export const environment = {
  production: false,
  apiUrl: 'https://app-dev.homehubb.com/Service/',
  defaultPage: 'main/home',
  loginPage: 'homehubb-login',
  shellName: 'HOMEHUBB-IONIC',
  loginRoute: '/homehubb-login',
  adTokenUrl: 'https://app-dev.homehubb.com/Service/api/Account/AdLogin',
  tokenUrl: 'https://app-dev.homehubb.com/Service/token',
  username: 'rob@idbpro.com',
  fileFolderPath: 'C:\\inetpub\\wwwroot\\app-dev.homehub.com\\Files',
  httpPath: 'https://app-dev.homehubb.com/files/',
  cometChatAppId:'201377232a357c04',
  cometChatAuthKey:'fec607df0dbcd6607f9dd23e4c6b6e60e2dd4984',
  cometChatRegion:'us'
};

import { Component, Pipe, PipeTransform, ViewEncapsulation, ViewChild, Output, ViewContainerRef, Compiler, NgModule, Host, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { Headers } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ShellService } from '../services/shell.service';
import { DataTable, RequestParameter, UploadResponse, QueryStringParameter, NonQuery, Scalar, SingleObject, Page, PageFunction, ReturnType, DictionaryItem, User } from '../models/data.types';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenInterceptor } from '../helpers/token.interceptor';
import { AuthenticationService } from '../services/authentication.service';
import { MbscModule, mobiscroll, MbscCalendarOptions, MbscEventcalendarOptions, MbscFormOptions, MbscRangeOptions, MbscListviewOptions } from '@mobiscroll/angular';
import { Capacitor } from '@capacitor/core';
import { IonicModule, NavController } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/services/toast-service';
import { environment } from '../../environments/environment';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Storage } from '@ionic/storage';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad';
import { LoadingController, IonSlides } from '@ionic/angular';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import * as _ from 'lodash';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { DateTime } from '@mobiscroll/angular/src/js/presets/datetime';
import { SocialSharing } from '@Ionic-native/social-sharing/ngx';

declare var jquery: any;


@Directive({
  selector: '[dynamicPage]'
})
export class DynamicPageDirective {
  constructor(
    private compiler: Compiler,
    private viewContainer: ViewContainerRef,
    public loadingController: LoadingController
  ) { }


  public addComponent(pageToLoad: Page, queryStringparams: QueryStringParameter[]) {



    @Component({ template: pageToLoad.Template, encapsulation: ViewEncapsulation.Emulated })
    class TemplateComponent {

      isUploading: boolean;
      user: User = new User;
      isBusy: boolean;
      pageLoaded: boolean = false;
      message: string;
      pageBag: any = {};
      dynamicpage: Page = pageToLoad;
      functions: PageFunction[] = [];
      tables: DictionaryItem<string, DataTable>[] = [];
      nonQueries: DictionaryItem<string, NonQuery>[] = [];
      objects: DictionaryItem<string, SingleObject>[] = [];
      doQueryResults: DictionaryItem<string, DataTable>[] = [];


      queryParams: QueryStringParameter[];
      mobiscrollObj = mobiscroll;
      @ViewChild(SignaturePad) signaturePad: SignaturePad;
      @ViewChild('slides') slides: IonSlides;

      constructor(
        private api: ApiService,
        private shell: ShellService,
        private router: Router,
        public nav: NavController,
        private toastr: ToastrService,
        private camera: Camera,
        private storage: Storage,
        private toaster: ToastService,
        private localNotifications: LocalNotifications,
        private file: File,
        private fileOpener: FileOpener,
        private calendar: Calendar,
        private socialSharing:SocialSharing
      ) {

      }

      share(msg:string,link:string,subject:string){
        this.socialSharing.share(msg,subject,link);
      }

      sendNotification(msg: string) {

        this.localNotifications.schedule({
          id: this.getHash(msg + new Date().getMilliseconds),
          text: msg
        });

      }

      addEventToCalendar(title:string,location:string,notes:string,startDate:Date,endDate:Date,showToaster:boolean,redirectToHome:boolean,successMessage:string) {
        let event = { title: title, location: location, message: notes, startDate: startDate, endDate: endDate };
        let self=this;
        this.calendar.createEvent(event.title,event.location, event.message, event.startDate, event.endDate).then(
          (msg) => {
            if(showToaster)
            {
              this.mobiscrollObj.toast({
                message: successMessage,
                duration:3000,
                color:'success',
                display:'top',
                callback:function(){
                  if(redirectToHome)
                  {
                    self.router.navigate([ '/main/mobile-home' ])
                  }
                }
              });
            }else if(redirectToHome)
            {
              self.router.navigate([ '/main/mobile-home' ])
            }
          },
          (err) => {

          }
        );
      }

      addPopEventToCalendar(title:string,location:string,notes:string,startDate:Date,endDate:Date,showToaster:boolean,redirectToHome:boolean,successMessage:string) {
        let event = { title: title, location: location, message: notes, startDate: startDate, endDate: endDate };
        let self=this;
        this.calendar.createEventInteractively(event.title,event.location, event.message, event.startDate, event.endDate).then(
          (msg) => {
            if(showToaster)
            {
              this.mobiscrollObj.toast({
                message: successMessage,
                duration:3000,
                color:'success',
                display:'top',
                callback:function(){
                  if(redirectToHome)
                  {
                    self.router.navigate([ '/main/mobile-home' ])
                  }
                }
              });
            }else if(redirectToHome)
            {
              self.router.navigate([ '/main/mobile-home' ])
            }
          },
          (err) => {

          }
        );
      }


      displayDocument(title: string, url: string) {

        this.api.downloadFileAndStore(title, url)
          .then((success) => {
            console.log("File created Succesfully");
            console.log(success);
            this.fileOpener.open(success, 'application/pdf')
              .then(() => console.log('File is opened'))
              .catch(e => console.log('Error opening file', e));
          })
          .catch((error) => {
            console.log("Cannot Create File " + JSON.stringify(error));
          });
      }

      getHash(input) {
        var hash = 0, len = input.length;
        for (var i = 0; i < len; i++) {
          hash = ((hash << 5) - hash) + input.charCodeAt(i);
          hash |= 0;
        }
        return hash;
      }

      ngOnInit() {

        mobiscroll.settings = {
          theme: 'ios'
        };

        this.queryParams = queryStringparams;
        this.loadPage();
      }

      loadPage(): Promise<any> {
        return this.api.get<PageFunction[]>('pages/getpagefunctionsbyroute/' + pageToLoad.Route).then(response => {

          this.functions = response;

          for (let f of this.functions) {
            if (f.ReturnTypeId == 1) { //void
              this.nonQueries[f.Name] = new NonQuery();
            }
            else if (f.ReturnTypeId == 2) { //dataTable
              this.tables[f.Name] = new DataTable();
            }
            else if (f.ReturnTypeId == 3) { //singleObject
              this.objects[f.Name] = new SingleObject();
            }
          }


          this.user = JSON.parse(localStorage.getItem('currentUser'));
          this.doInitFunctions();

          eval("var _this = this; " + pageToLoad.Script);

          this.pageLoaded = true;

        });
      }

      private doInitFunctions() {

        let fParams: RequestParameter[] = [];

        for (let f of this.functions) {
          if (f.OnLoad) {

            for (let p of f.Parameters) {

              for (let qsp of queryStringparams) {
                if (qsp.Name == p.Name && p.DefaultValue != null && p.DefaultValue.startsWith('?')) {
                  let rp = new RequestParameter();
                  rp.Name = qsp.Name;
                  rp.Value = qsp.Value;
                  fParams.push(rp);
                }
              }

            }

            if (fParams.length == 0 && f.Parameters.length > 0) {
              for (let p of f.Parameters) {
                if (p.DefaultValue != null) {
                  let rp = new RequestParameter();
                  rp.Name = p.Name;

                  if (p.DefaultValue.startsWith('$Global.')) {

                  }
                  else {
                    rp.Value = p.DefaultValue;
                  }
                  fParams.push(rp);
                }
              }
            }

            if (f.ReturnTypeId == 1) { //void
              console.log('init void ' + f.Name);
              this.nonQueries[f.Name].isBusy = true;
              this.nonQueries[f.Name].isLoading = true;

              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

              this.api.putAs<NonQuery>('functions/executeasnonquery/' + f.Id, fParams).then(response => {
                this.nonQueries[f.Name] = response;
                this.nonQueries[f.Name].isBusy = false;
                this.nonQueries[f.Name].isLoading = false;
                this.nonQueries[f.Name].isLoaded = true;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
                .catch(
                  () => {
                    if (f.OnError != null) {
                      eval(f.OnError);
                    }
                  });
            }
            else if (f.ReturnTypeId == 2) { //dataTable
              console.log('init dataTable ' + f.Name);
              this.tables[f.Name].isBusy = true;
              this.tables[f.Name].isLoading = true;

              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

              this.api.putAs<DataTable>('functions/executeasdatatable/' + f.Id, fParams).then(response => {
                this.tables[f.Name] = response;
                this.tables[f.Name].isBusy = false;
                this.tables[f.Name].isLoading = false;
                this.tables[f.Name].isLoaded = true;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
                .catch(
                  () => {
                    if (f.OnError != null) {
                      eval(f.OnError);
                    }
                  });
            }
            else if (f.ReturnTypeId == 3) { //singleObject
              console.log('init singleObject ' + f.Name);
              this.objects[f.Name].isBusy = true;
              this.objects[f.Name].isLoading = true;

              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

              this.api.putAs<SingleObject>('functions/executeassingleobject/' + f.Id, fParams).then(response => {
                this.objects[f.Name] = response;
                this.objects[f.Name].isBusy = false;
                this.objects[f.Name].isLoading = false;
                this.objects[f.Name].isLoaded = true;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
                .catch(
                  () => {
                    if (f.OnError != null) {
                      eval(f.OnError);
                    }
                  });
            }
          }
        }
      }

      public do(functionName: string, o: any): Promise<boolean> {

        this.isBusy = true;

        let f = this.functions.find(x => x.Name == functionName);
        if (!f) {
          console.log('Function ' + functionName + ' was not found.');
          return;
        }

        console.log('do ' + f.Name);

        let fParams: RequestParameter[] = [];

        if (o != null) {
          for (let p of f.Parameters) {

            let rp = new RequestParameter();
            rp.Name = p.Name;

            let pval = Reflect.get(o, p.Name);

            if (pval) {
              console.log('setting ' + p.Name + ' = ' + pval);
              rp.Value = pval;
            }
            else {

              if (p.DefaultValue != null) {
                // if(p.DefaultValue.startsWith('$Global.')) {
                //     let gVal = this.getGlobal(p.DefaultValue);
                //     console.log('setting ' + p.Name + ' = ' + gVal);
                //     rp.Value = gVal;
                // }
              }

              console.log('setting ' + p.Name + ' = NULL');
            }

            fParams.push(rp);
          }
        }

        console.log('calling api');
        if (f.ReturnTypeId == 1) { //void
          this.nonQueries[f.Name].isBusy = true;
          this.nonQueries[f.Name].isLoading = true;

          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }

          this.api.putAs<NonQuery>('functions/executeasnonquery/' + f.Id, fParams).then(response => {
            console.log("here is the response");
            console.log(response);
            this.nonQueries[f.Name] = response;
            this.nonQueries[f.Name].isBusy = false;
            this.nonQueries[f.Name].isLoading = false;
            this.nonQueries[f.Name].isLoaded = true;
            this.isBusy = false;

            if (f.OnSuccess != null) {
              eval(f.OnSuccess);
            }
          })
            .catch(
              () => {
                if (f.OnError != null) {
                  eval(f.OnError);
                }
              });
        }
        else if (f.ReturnTypeId == 2) { //dataTable
          this.tables[f.Name].isBusy = true;
          this.tables[f.Name].isLoading = true;

          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }

          this.api.putAs<NonQuery>('functions/executeasdatatable/' + f.Id, fParams).then(response => {
            console.log("here is the response");
            console.log(response);
            this.tables[f.Name] = response;
            this.tables[f.Name].isBusy = false;
            this.tables[f.Name].isLoading = false;
            this.tables[f.Name].isLoaded = true;
            this.isBusy = false;

            if (f.OnSuccess != null) {
              eval(f.OnSuccess);
            }
          })
            .catch(
              () => {
                if (f.OnError != null) {
                  eval(f.OnError);
                }
              });
        }
        else if (f.ReturnTypeId == 3) { //singleObject
          this.objects[f.Name].isBusy = true;
          this.objects[f.Name].isLoading = true;

          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }

          this.api.putAs<SingleObject>('functions/executeassingleobject/' + f.Id, fParams)

            .then(
              response => {

                console.log("here is the response");
                console.log(response);
                this.objects[f.Name] = response;
                this.objects[f.Name].isBusy = false;
                this.objects[f.Name].isLoading = false;
                this.objects[f.Name].isLoaded = true;
                this.isBusy = false;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
            .catch(
              () => {
                if (f.OnError != null) {
                  eval(f.OnError);
                }
              });
        }
      }

      

      public doQuery(functionName: string, o: any, pageBagName: string) {

        this.isBusy = true;


        console.log('doQuery: ' + functionName);

        console.log('calling api');

        this.doQueryResults[pageBagName] = new DataTable();

        this.doQueryResults[pageBagName].isBusy = true;
        this.doQueryResults[pageBagName].isLoading = true;

        if (o.length >= 1) {
          let newO: any = new Object;
          let stringObject: string;
          o.forEach(element => {
            newO[Object.keys(element)[0]] = element[Object.keys(element)[0]];
          });
          o = newO;
        }
        return this.api.postAs<any>('data/doquery/' + functionName, o)
          .then(
            response => {

              console.log("here is the response");
              console.log(response);
              this.doQueryResults[pageBagName] = response;
              this.doQueryResults[pageBagName].isBusy = false;
              this.doQueryResults[pageBagName].isLoading = false;
              this.doQueryResults[pageBagName].isLoaded = true;
              this.isBusy = false;

              console.log("Response save to doQueryResults['" + pageBagName + "']");
            })
          .catch(() => {
            console.log("Do Query Error")
          });
      }

      public open(url: string, params: any) {

        let pArray = Reflect.ownKeys(params);

        pArray.forEach(element => {
          url += '&' + element.toString() + '=' + Reflect.get(params, element.toString());
        });

        window.open(url);
      }

      upload(fileArea: string, fileType: string, newFileName: string, content: string) {
        return this.api.postAs<UploadResponse>('data/doupload',
          {
            Name: newFileName,//this.fileToUpload.name, 
            Size: content.length,
            Data: content,
            FileArea: fileArea,
            FileType: fileType,
            FilesFolderPath: this.api.getfolderPath(),
            HttpPath: this.api.gethttpUrl(),
            UserId: this.user != null ? this.user.username : "Anonymous"
          })
          .then(response => {
            console.log(response);
            if (response.hasError) {
              console.log(response.errorMessage);
            }
            else {
              console.log("File Uploaded");
              return response;
            }
            this.isUploading = false;
          });
      }

      public sendEmail(receipents: string, subject: string, body: string, attachment: string, fileName: string, cc: string, bcc: string) {
        this.isBusy = true;

        let emailData: any = {};
        emailData.email = receipents;
        emailData.subject = subject;
        emailData.body = body;

        if (cc) {
          emailData.CC = cc;
        }
        if (bcc) {
          emailData.BCC = bcc;
        }


        if (attachment != null && attachment.length > 0) {
          let attachmentsList: any = []
          let attachmentData: any = {};
          attachmentData.key = attachment
          attachmentData.value = fileName

          attachmentsList.push(attachmentData)

          emailData.attachments = attachmentsList
        }

        this.api.postAs<any>('Admin/SendMail/', emailData).then(x => {
          this.isBusy = false;
          console.log(x);
        });
      }


      public getDate() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var day = date.getDate();
        var dayString = '';
        var month = date.getMonth() + 1;
        var monthString = '';
        var year = date.getFullYear();
        if (month < 10)
          monthString = '0' + month.toString();
        else
          monthString = month.toString();
        if (day < 10)
          dayString = '0' + day.toString();
        else
          dayString = day.toString();
        var dateString = year + '-' + monthString + '-' + dayString;

        return dateString;
      }

      public getFirstDateOfMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);

        var day = firstDay.getDate();
        var dayString = '';
        var month = firstDay.getMonth() + 1;
        var monthString = '';
        var year = firstDay.getFullYear();
        if (month < 10)
          monthString = '0' + month.toString();
        else
          monthString = month.toString();
        if (day < 10)
          dayString = '0' + day.toString();
        else
          dayString = day.toString();
        var dateString = year + '-' + monthString + '-' + dayString;

        return dateString;
      }

      public convertUrl(url: string) {
        return Capacitor.convertFileSrc(url);
      }

    }

    @Pipe({ name: 'safe' })
    class SafePipe implements PipeTransform {
      constructor(private sanitizer: DomSanitizer) { }
      transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }
    @NgModule({
      declarations: [
        TemplateComponent,
        SafePipe
      ],
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        AuthenticationService,
        JwtHelperService,
        Camera,
        FileOpener,
        File
      ],
      imports: [
        MbscModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        IonicModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SignaturePadModule,
        ToastrModule.forRoot(),
      ]
    })
    class TemplateModule { }

    if (this.viewContainer.length > 0) {
      this.viewContainer.remove();
    }

    this.compiler.clearCache();

    const mod = this.compiler.compileModuleAndAllComponentsSync(TemplateModule);
    const factory = mod.componentFactories.find((comp) =>
      comp.componentType === TemplateComponent
    );

    const compRef = this.viewContainer.createComponent(factory);

    setTimeout(() => {

      if (!(compRef.instance as any).pageLoaded) {
        if (!document.getElementsByTagName('ion-loading')[0]) {


          this.loadingController.create({
            spinner: 'crescent',
            message: 'Loading...',
            translucent: true,
            mode: 'md',
            cssClass: 'loading-controller'
          }).then(loading => loading.present());

        }
      }
    }, 2000);

    const interval = setInterval(function () {
      if ((compRef.instance as any).pageLoaded) {
        this.loadingController.dismiss();
        clearInterval(interval);

      }
    }.bind(this), 50);

    return compRef;
  }
}
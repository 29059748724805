import { Component, ViewChild, ContentChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { ApiService } from '../services/api.service';
import { QueryStringParameter, Page, User } from '../models/data.types';
import { DynamicHeaderDirective, DynamicPageDirective, DynamicFooterDirective } from '../directives';
import { Headers } from '@angular/http';
import { ViewContainerRef, Compiler } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { Subscription, combineLatest } from "rxjs";
import { Observable } from 'rxjs/Observable';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { IonicModule, NavController } from '@ionic/angular';
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-web',
  templateUrl: './web.html'
})
export class WebPage {

  sub: any;
  pageName: string;
  pageTitle: string;
  pageId: number;
  page: Page = new Page();
  currentUser: any = {};
  pagename: string;
  title: string;
  HomeGUID: string;
  IsDev: string;


  @ViewChild(DynamicPageDirective) dynamicPage: DynamicPageDirective;

  constructor(private compiler: Compiler,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public nav: NavController,
    private sanitizer: DomSanitizer) {

    this.route.queryParams.subscribe(params => {
      let homeguid = params['homeguid'];
      let isDev = params['isdev'];

      if (homeguid != null) {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        if (user == null) {
          user = new User();
        }
        user.SelectedHomeGUID = homeguid;

        localStorage.setItem('currentUser', JSON.stringify(user));

        if (isDev != undefined) {
          if (isDev == 1) {
            localStorage.setItem('APIEndPoint', 'https://app-dev.homehubb.com/service/api/');
            localStorage.setItem('TokenUrl', 'https://app-dev.homehubb.com/service/token');
          }
          else {
            localStorage.setItem('APIEndPoint', 'https://app.homehubb.com/service/api/');
            localStorage.setItem('TokenUrl', 'https://app.homehubb.com/service/token');
          }
        }
      }

    });
  }

  ionViewWillEnter() {
    this.sub = this.route.params.subscribe(params => {

      if (params['id'] == null) {
        this.pagename = 'SystemDashboardPage';
      }
      else {
        this.pagename = params['id'];
      }

      let body = JSON.parse('{"route": "' + this.pagename + '"}');



      this.LoadDynamicPage(this.pagename, null, this.dynamicPage, null);
    });
  }

  // ngAfterViewInit() {

  //     this.sub = this.route.params.subscribe(params => {

  //       if (params['id'] == null) {
  //         this.pagename = 'SystemDashboardPage';
  //       }
  //       else {
  //         this.pagename = params['id'];
  //       }

  //       let body = JSON.parse('{"route": "'+this.pagename +'"}');

  //       this.LoadDynamicPage(this.pagename,this.dynamicHeader,this.dynamicPage,this.dynamicFooter);  
  //   });
  // }



  async LoadDynamicPage(pageName: String,
    dynamicHeaderDirective: DynamicHeaderDirective,
    dynamicPageDirective: DynamicPageDirective,
    dynamicFooterDirective: DynamicFooterDirective) {
    let currentUser: User;
    currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser == null || currentUser.token == null) {
      await this.apiService.getToken();
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.apiService.authHeaders = new HttpHeaders();
    this.apiService.authHeaders.append('Content-Type', 'application/json');
    this.apiService.authHeaders.append(
      'Authorization', 'bearer ' + currentUser.token
    );


    return this.apiService.get<Page>('pages/getpagebyroute/' + pageName).then(response => {

      let queryStringparams: QueryStringParameter[] = [];

      let pArray = Reflect.ownKeys(this.route.snapshot.queryParams);

      pArray.forEach(element => {
        let qsp = new QueryStringParameter();
        qsp.Name = element.toString();
        qsp.Value = Reflect.get(this.route.snapshot.queryParams, element.toString());
        queryStringparams.push(qsp);
      });

      this.page = response;




      var mainComponent = dynamicPageDirective.addComponent(this.page, queryStringparams);

    }).catch(e => {
      if (e.status === 401) {
        //  this.router.navigate(['/page/login']);
        console.log(e);

        this.apiService.get<Page>('pages/getpagebyroute/401').
          then(response => {
            dynamicPageDirective.addComponent(response, null);
          });


        return "";
      }
      else {
        return "404";
      }

    });

  };

}

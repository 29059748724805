import { MbscModule } from '@mobiscroll/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ToastService } from './services/toast-service';

import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { IonicStorageModule } from '@ionic/storage';

import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtModule } from '@auth0/angular-jwt';

import { LoginComponent } from './login/login.component';
import { ChatViewComponent } from './chat-view/chat-view.component';
import { ApiService } from './services/api.service';
import { ShellService } from './services/shell.service';
import { AuthenticationService } from './services/authentication.service';
import { TokenInterceptor } from './helpers/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective } from '../app/directives';
import { MainPage } from '../app/main/main'
import { WebPage } from '../app/web/web'
import { HTTP_INTERCEPTORS ,HttpClientModule} from "@angular/common/http";
import { User } from '../app/models/data.types'

import { Network } from '@ionic-native/network/ngx';
import { AuthGuard } from './helpers/index';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { SocialSharing } from '@Ionic-native/social-sharing/ngx';

//import { Chooser } from '@ionic-native/chooser';
//import { ImagePicker } from '@ionic-native/image-picker';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';

export function tokenGetter() {
  let currentUser : User;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  if(currentUser == null)
  {
      return "";
  }
  return currentUser.token;
}


@NgModule({
  declarations: [MainPage,WebPage, AppComponent,DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective,ImageViewerComponent],
  exports: [MainPage,WebPage,DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective],
  entryComponents: [ImageViewerComponent],
  imports: [ 
    MbscModule,  
    FormsModule,  
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SignaturePadModule,
    HttpModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
       }
      }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserModule, HttpModule, HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    SuperTabsModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [    
    {provide: HTTP_INTERCEPTORS,useClass: TokenInterceptor,multi: true},
    Deeplinks,
    StatusBar,
    SplashScreen,
    OneSignal,
    LocalNotifications,
    ApiService,
    ShellService,
    AuthenticationService,
    JwtHelperService,
    Network,
    AuthGuard,
    Camera,
    Calendar,
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BarcodeScanner,
    ToastService,
    FileOpener,
    HTTP,
    File,
    SocialSharing
    //Chooser,
    //ImagePicker
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { User } from '../models/data.types'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment'
import { RequestOptions } from '@angular/http';

@Injectable()
export class AuthenticationService {

    baseUrl: string;
    FindUserUrl: string;
    options :any;

    constructor(public jwtHelper: JwtHelperService) { 
        
        this.baseUrl = environment.apiUrl;
        this.FindUserUrl = `${this.baseUrl}service/api/Account/AuthenticateUser`;
        this.options = new RequestOptions({ withCredentials: true });
        
    }

    public getToken(): string {
        let currentUser : User;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if(currentUser == null)
        {
          currentUser = JSON.parse(localStorage.getItem('user'));
          if(currentUser == null)
          {
              return "";
          }
        }
        return currentUser.token;
      }

    public getUser(): User {

        let currentUser : User;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if(currentUser == null)
        {
            return null;
        }
        return currentUser;
      }

    public setToken(token: string) {

        let currentUser : User;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if(currentUser != null)
        {
            currentUser.token = token;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
      }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        return this.jwtHelper.isTokenExpired(token);
      }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }

     
}

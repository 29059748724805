import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment'
import { RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {

    baseUrl: string;
    FindUserUrl: string;
    options :any;

    constructor(private router: Router,private http: HttpClient) {    
        this.baseUrl = environment.apiUrl;
        this.FindUserUrl = `${this.baseUrl}service/api/Account/AuthenticateUser`;
        this.options = new RequestOptions({ withCredentials: true }); 
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            return true;
        }
        else
        {
            this.router.navigateByUrl(environment.loginPage);
            return false;
        }
       
    }
}
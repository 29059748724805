import { Component, ViewChild ,ContentChild,ElementRef} from '@angular/core';
import { Router,  ActivatedRoute, Params ,NavigationEnd} from '@angular/router';
import { ApiService } from '../services/api.service';
import { QueryStringParameter, Page,User} from '../models/data.types';
import { DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective} from  '../directives';
import { Headers } from '@angular/http';
import { ViewContainerRef, Compiler} from '@angular/core';
import { HttpHeaders} from "@angular/common/http";
import { Subscription,combineLatest } from "rxjs";
import { Observable } from 'rxjs/Observable';
import { map, startWith,withLatestFrom } from 'rxjs/operators';
import { IonicModule ,NavController} from '@ionic/angular';
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.html'
})
export class MainPage {

  sub: any;
  pageName: string;
  pageTitle: string;
  pageId: number;
  page: Page = new Page();
  currentUser: any = {};
  pagename: string;
  title: string;


  @ViewChild(DynamicHeaderDirective) dynamicHeader: DynamicHeaderDirective;
  @ViewChild(DynamicPageDirective) dynamicPage  : DynamicPageDirective;
  @ViewChild(DynamicFooterDirective) dynamicFooter: DynamicFooterDirective;
 
  constructor(private compiler: Compiler,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public nav: NavController,
    private sanitizer: DomSanitizer) {

  }

  ionViewWillEnter() {
    this.sub = this.route.params.subscribe(params => {

      if (params['id'] == null) {
        this.pagename = 'SystemDashboardPage';
      }
      else {
        this.pagename = params['id'];
      }

      let body = JSON.parse('{"route": "'+this.pagename +'"}');

      this.LoadDynamicPage(this.pagename,this.dynamicHeader,this.dynamicPage,this.dynamicFooter);  
   });
  }

  // ngAfterViewInit() {
      
  //     this.sub = this.route.params.subscribe(params => {

  //       if (params['id'] == null) {
  //         this.pagename = 'SystemDashboardPage';
  //       }
  //       else {
  //         this.pagename = params['id'];
  //       }

  //       let body = JSON.parse('{"route": "'+this.pagename +'"}');

  //       this.LoadDynamicPage(this.pagename,this.dynamicHeader,this.dynamicPage,this.dynamicFooter);  
  //   });
  // }



  LoadDynamicPage(pageName : String,
                  dynamicHeaderDirective : DynamicHeaderDirective ,
                  dynamicPageDirective : DynamicPageDirective,
                  dynamicFooterDirective : DynamicFooterDirective) 
  {
    let currentUser : User;
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser != null) {

      this.apiService.authHeaders = new HttpHeaders();
      this.apiService.authHeaders.append('Content-Type', 'application/json');
      this.apiService.authHeaders.append(
        'Authorization', 'bearer ' + currentUser.token
      );
    }

    

    return this.apiService.get<Page>('pages/getpagebyroute/' + pageName).then(response => {
   
      let queryStringparams: QueryStringParameter[] = [];

      let pArray = Reflect.ownKeys(this.route.snapshot.queryParams);

      pArray.forEach(element => {
        let qsp = new QueryStringParameter();
        qsp.Name = element.toString();
        qsp.Value = Reflect.get(this.route.snapshot.queryParams, element.toString());
        queryStringparams.push(qsp);
      });

      this.page = response;

      this.page.Header = response.Header === null ? `<ion-toolbar>
      <ion-buttons slot="start">
      <ion-button color="primary" (click)="main.nav.back();">
        <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
        Back
      </ion-button>
      
      </ion-buttons>
      <ion-title>
       <ion-label style="color:rgb(243,166,70);font-size:20px">`+this.page.Title+`</ion-label> 
      </ion-title>
      <ion-buttons slot="end">
      <ion-button color="primary" routerLink="/main/mobile-search">
        <ion-icon slot="icon-only"  name="search"></ion-icon>
      </ion-button>
      <ion-menu-button color="primary"> </ion-menu-button>
      </ion-buttons>
      </ion-toolbar>` : response.Header;

      this.page.Footer = response.Footer === null ? `<!-- Footer without a border -->
      <ion-footer>
        <ion-toolbar color="primary" style="bottom:0">   
          <ion-row>
            <ion-col style="display:flex;justify-content:center;align-items: center;">
              <ion-button  (click)="router.navigate(['main/mobile-home']);">
                <ion-img  src="https://app-dev.homehubb.com/files/appicons/HOME.png" style="width:40px">   </ion-img> 
                </ion-button>
              </ion-col>
            <ion-col style="display:flex;justify-content:center;align-items: center;border-left:thin solid #FFFFFF;">
              <ion-label class="ion-align-items-baseline" style="color:#fff ;font-size:8px;display: inline-flex;">Powered by &nbsp;
             <ion-img src="https://app-dev.homehubb.com/files/appicons/HH icon White.png"  style="width:30px;">
             </ion-img> &nbsp; HomeHubb, LLC </ion-label>
              </ion-col>
            </ion-row>
      </ion-toolbar>
      
      </ion-footer>`: response.Footer;


     var mainComponent = dynamicPageDirective.addComponent(this.page,queryStringparams);

      dynamicHeaderDirective.addHeader(this.page.Title,this.page.Header,mainComponent.instance);
      
      dynamicFooterDirective.addFooter(this.page.Footer,mainComponent.instance);

    }).catch(e => {
        if (e.status === 401) {
            //  this.router.navigate(['/page/login']);
            console.log(e);

            this.apiService.get<Page>('pages/getpagebyroute/401').
            then(response => {
              dynamicPageDirective.addComponent(response,null);
            });

            
            return "";
          }
          else
          {
            return "404";
          }
       
      });
    
    };

}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
 
export class StorageItem {
  created: string;
  value: string;
  modified: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
 
  constructor(private storage: Storage) { }
 
  getItem(key: string) {
    return this.storage.get(key);
  }

  // READ All
  getTable(key: string): Promise<StorageItem> {
    return this.storage.get(key);
  }

  // UPSERT
  upsertTable(key: string,item: StorageItem): Promise<any> {

        let newItems: StorageItem = item;

        return this.storage.set(key, newItems);
  }
 
  // DELETE
  deleteTable(key: string): Promise<StorageItem> {
    return this.storage.get(key).then((items: StorageItem) => {
      if (!items) {
        return null;
      }
      return this.storage.remove(key);
    });
  }
}
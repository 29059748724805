import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainPage } from '../app/main/main';
import { WebPage } from '../app/web/web';
import { AuthGuard } from '../app/helpers/index';
import { ShellService } from './services/shell.service';
import { LoginComponent } from '../app/login/login.component';
import { ChatViewComponent } from '../app/chat-view/chat-view.component';
import { environment } from './../environments/environment'
import { RegistrationComponent } from './registration/registration.component';
import { TermOfServiceComponent} from './term-of-service/term-of-service.component';
import{ ResetPasswordComponentModule }from './resetpassword/resetpassword.module';

const routes: Routes = [
  { path: '', redirectTo: 'main/mobile-home', pathMatch: 'full' },
  { path: 'homehubb-login',         
    loadChildren: './login/login.module#LoginComponentModule', 
    data: { title: 'Login Page' }},
    { path: 'chat-view', loadChildren: './chat-view/chat-view.module#ChatViewComponentModule' , data: { title: 'Chat' }},
    { path: 'main/:id', component: MainPage,resolve: { ShellService }, canActivate: [AuthGuard]}
  , { path: 'web/:id', component: WebPage,resolve: { ShellService }}
  , { path: 'homehubb-Registration', loadChildren :'./registration/registration.module#registrationComponentModule'
 
  ,data: { title: 'Registration Page' }}
  , { path: 'homehubb-Forgotpassword', loadChildren : './forgot-password/forgot-password.module#forgotPasswordComponentModule'
  ,data: { title: 'Forgot Password Page' }}

  , { path: 'homehubb-Resetpassword', loadChildren : './resetpassword/resetpassword.module#ResetPasswordComponentModule'
  ,data: { title: 'Reset Password Page' }}

  , { path: 'homehubb-Thankyou', loadChildren : './thankyou/thankyou.module#ThankyouComponentModule'
  ,data: { title: 'Thank you Page' }}
  , { path: 'homehubb-Termsofservice', loadChildren : './term-of-service/term-of-service.module#TermOfServiceComponentModule'
  ,data: { title: 'Terms Of Service' }}
  , { path: 'homehubb-Privacypolicy', loadChildren : './privacy-policy/privacy-policy.module#PrivacyPolicyComponenttModule'
  ,data: { title: 'Privacy policy' }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

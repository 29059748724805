import { Router, RouterStateSnapshot } from '@angular/router'
import { Platform } from '@ionic/angular';;
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { NonQuery, DataTable, Session, User, PageFunction, LocalAPIs } from '../models/data.types';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/catch';
import { Storage } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { CometChat } from '@cometchat-pro/cordova-ionic-chat';

@Injectable()
export class ApiService {

    isLoggedIn: boolean = false;
    roles: string[];
    redirectUrl: string;
    defaultHeaders: HttpHeaders;
    authHeaders: HttpHeaders;
    tokenHeaders: HttpHeaders;
    headers: HttpHeaders;

    apiUrl: string;
    baseUrl: string;
    tokenUrl: string;
    userName: string;
    token: any;
    user: User;
    IsProd: boolean = true;

    state: any;

    constructor(private nativeHTTP: HTTP,
        private http: HttpClient,
        private router: Router,
        private storage: Storage,
        private file: File,
        private platform: Platform,) {
        this.defaultHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.tokenHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.baseUrl = environment.apiUrl;
        //this.apiUrl = `${this.baseUrl}api/`;
        //this.tokenUrl = `${this.baseUrl}token`;
        this.state = router.routerState.snapshot;

        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app.homehub.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
    }


    getApiUrl() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app.homehub.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("APIEndPoint");
    }

    gettokenUrl() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app.homehub.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("TokenUrl");
    }

    gethttpUrl() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app.homehub.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("HttpUrl");
    }

    getfolderPath() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app.homehub.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("FolderPath");
    }

    loadUser() {

        if (localStorage.getItem('currentUser') != null) {

            this.user = JSON.parse(localStorage.getItem('currentUser'));
            this.isLoggedIn = true;

            this.authHeaders = new HttpHeaders();
            this.authHeaders.append('Content-Type', 'application/json');
            this.authHeaders.append('Authorization', 'bearer ' + this.user.token);

            console.log('loaded user from cache...');
        }
    }

    login(userName: string, passWord: string): Promise<Session> {

        let tokenRequest = 'grant_type=password&username=' + userName + '&password=' + passWord;

        return this.http.post(
            this.gettokenUrl(),
            tokenRequest, {
            headers: this.tokenHeaders
        })
            .toPromise()
            .then(x => {
                this.isLoggedIn = true;
                return x;


                // return this.http.post(`${this.getApiUrl()}data/doquery/HM_CheckIsActive`, { username: userName })
                // .toPromise().then((w: any) => {
                //     debugger;
                //     this.isLoggedIn = true;
                //     return x;
                // })
                // .catch(w => w);
            })
            .catch(x => x);
    }

    adLogin(userName: string, passWord: string): Promise<any> {
        let tokenRequest = 'username=' + userName + '&password=' + passWord;
        return this.http.post(
            environment.adTokenUrl,
            tokenRequest, {
            headers: this.tokenHeaders
        })
            .toPromise()
            .then(x => {

                this.isLoggedIn = true;
                return x;

            })
            .catch(x => x);
    }

    logout() {
        localStorage.clear();
        this.authHeaders = null;
        this.storage.clear();
        this.IsProd = true;
        CometChat.logout().then(
            (user) => {
              console.log("Logout successfull:");
              this.router.navigate(["/login"]);
            },
            (error) => {
              console.log("Logout failed", { error });
            }
          );
        this.router.navigate([environment.loginRoute]);
    }

    logoutWithoutRedirect() {
        localStorage.removeItem("currentUser");
        this.authHeaders = null;
    }

    get<T>(url: string): Promise<T> {
        return this.http.get(this.getApiUrl() + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    put(url: string, o: any) {
        this.http.put(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().catch(this.handleError);
    }

    putAs<T>(url: string, o: any): Promise<T> {
        return this.http.put(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    post(url: string, o: any) {
        this.http.post(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().catch(this.handleError);
    }

    postAs<T>(url: string, o: any): Promise<T> {
        return this.http.post(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }



    async getToken() {
        try {
            const q: any = await this.http.post(`${this.getApiUrl()}data/doquery/APITokenCredintials`, {}).toPromise();
            let tokenRequest = q.Result.Value;

            const response = await this.http.post(this.gettokenUrl(),
                tokenRequest, {
                headers: this.tokenHeaders
            }).toPromise();

            let session = JSON.parse(JSON.stringify(response));
            var user = JSON.parse(localStorage.getItem('currentUser'));
            if (user == null) {
                user = new User();
            }

            user.token = session.access_token.toString();
            localStorage.setItem('currentUser', JSON.stringify(user));
        } catch (x) {
            return x;
        }
    }

    delete<T>(url: string): Promise<T> {
        return this.http.delete(this.getApiUrl() + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    getNoError<T>(url: string): Promise<T> {
        return this.http.get(this.getApiUrl() + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.SkipError);
    }

    private SkipError(error: any) {
        console.log('LocalQuery');
        return Promise.resolve(error);
    }

    userLogin(userName: string, passWord: string) {
        let tokenRequest = 'grant_type=password&username=' + userName + '&password=' + passWord;
        return this.http.post(
            this.tokenUrl,
            tokenRequest, {
            headers: this.tokenHeaders
        })
            .map(x => {
                localStorage.setItem('session', JSON.stringify(x));
                let session = JSON.parse(JSON.stringify(x));

                let user: User = new User();
                user.username = userName;
                user.password = passWord;
                user.token = JSON.parse(session._body).access_token.toString();
                localStorage.setItem('currentUser', JSON.stringify(user));

                this.isLoggedIn = true;
                return x;
            })
            .catch(x => x);
    }


    public downloadFileAndStore(fileName, fileURL) {
        //

        if (fileName === '') {
            fileName = 'tempfile.pdf'
        }

        let filePath = null;
        if (this.platform.is('cordova')) {
            if (this.file.documentsDirectory === null) {
                filePath = this.file.dataDirectory + fileName;
            }
            else {
                filePath = this.file.documentsDirectory + fileName;
            }
        }

        return this.nativeHTTP.downloadFile(fileURL, {}, {}, filePath).then(response => {
            // prints 200
            return filePath;
            console.log('success block...', response);
        }).catch(err => {
            // prints 403
            console.log('error block ... ', err.status);
            // prints Permission denied
            console.log('error block ... ', err.error);
        })
    }


    ChangeEnvironment(changeToEnvironment) {
        this.logout();
        if (changeToEnvironment === 'Production') {

            localStorage.setItem('APIEndPoint', "https://app.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app.homehub.com\\Files");
            
            this.IsProd = true;
        }
        else {
            localStorage.setItem('APIEndPoint', "https://app-dev.homehubb.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app-dev.homehubb.com/service/token");
            localStorage.setItem('HttpUrl', "https://app-dev.homehubb.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\wwwroot\\app-dev.homehub.com\\Files");
            this.IsProd = false;
        }
        console.log(localStorage.getItem("APIEndPoint"));
    }


    private handleApiError(error: any, LocalQueryCalls: LocalAPIs, resolve: any) {
        LocalQueryCalls.Errors.push(error);
        return resolve(LocalQueryCalls);
    }
}